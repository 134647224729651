import styled from "@emotion/styled";
import { Grid, Typography} from "@mui/material";

const PageWrapper = styled.div`
  background-color: #010117;
  position: relative;
  overflow: hidden;
  /* overflow: hidden !important; */
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "100%")};
  left: ${(props) => (props.l ? props.l : "100%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-100%,-100%)")};
  max-width: ${(props) => (props.mw ? props.mw : "45%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  z-index: -1;
  @media (max-width: 599px) {
    /* opacity:0.5; */
    max-width: ${(props) => (props.mw599 ? props.mw599 : "50%")};
  }
`;

const CustomGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "start")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  @media (max-width: 599px) {
    flex-direction: ${(props) => props.fd599};
    align-items: ${(props) => (props.ai599)};
  }
`;
const SectionHeading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "left")};
  font-size: ${(props) => (props.fs ? props.fs : "120px")};
  font-family: ${(props) => (props.fm ? props.fm : "Cloister")};
  line-height: 1;
  position: relative;
  color: ${(props) => (props.c ? props.c : "#fff")};
  @media (max-width: 1175px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs1175 ? props.fs1175 : "86px")};
  }
  @media (max-width: 1024px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs1024 ? props.fs1024 : "90px")};
  }
  @media (max-width: 599px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "60px")};
  }
`;
export {
  PageWrapper,
  DataImage,
  AbsoluiteImage,
  CustomGrid,SectionHeading
};
