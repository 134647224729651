import React, { useRef } from "react";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  PageWrapper,
  SectionHeading,
} from "../../Styles/style";
import { Button, Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import background from "../../../assets/background.png";
import etherscan from "../../../assets/etherscan.png";
import dex from "../../../assets/dex.png";
import uniswap from "../../../assets/uniswap.png";
import tg from "../../../assets/tg.png";
import x from "../../../assets/x.png";
import copy from "../../../assets/copy.png";
import maleImage from "../../../assets/maleImage.png";
import music from "../../../assets/music/music.mp3";
import Paragraph from "../../Components/Paragraph";
import { useState, useEffect } from "react";
const ContainerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  background: #000;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
`;
const CustomButton = styled(Button)`
  background: #fff;
  color: #000;
  border: none;
  font-family: Cloister;
  text-transform: uppercase;
  :hover {
    background: #fff;
    color: #000;
  }
`;
const MainPage = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const addressToCopy = "0x66e43a83749d07e272f1175ce4010a9b5bbdaebc";
  const audioRef = useRef(null);

  useEffect(() => {
    // Access the audio element using the ref
    const audio = audioRef.current;

    // Check if the audio element exists
    if (audio) {
      // Autoplay the audio
      audio
        .play()
        .then(() => {
          // Audio started playing
          console.log("Audio started playing");
        })
        .catch((error) => {
          // Autoplay was prevented, handle the error
          console.error("Autoplay was prevented:", error);
        });
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };
  return (
    <PageWrapper>
      <ContainerWraper maxWidth="xxl">
        <AbsoluiteImage src={maleImage} />
        <Container minHeight="100vh">
          <CustomGrid container minHeight="100vh" ai599="start" p="50px 0 0">
            <CustomGrid container item xs={12} sm={8} md={6} g="20px">
              <CustomGrid item xs={12}>
                <SectionHeading>Black Male</SectionHeading>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <Paragraph>
                  Introducing BlackMale.me – where financial empowerment meets
                  crypto! Meet our charismatic protagonist, a benevolent Black
                  Male who's a visionary. Known for his unparalleled generosity
                  and affluence, he's on a mission to redefine success and
                  philanthropy. Are you looking forward to getting Black Male(d)
                  with money?. Discover the charm, wit, and wealth that make him
                  an icon, all while exploring the unique world of crypto
                  trading. Join the revolution at BlackMale.me, where wealth
                  and whimsy collide!
                </Paragraph>
              </CustomGrid>
              <CustomGrid
                item
                xs={12}
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  position: "relative",
                }}
                jc="space-between"
                p="5px 10px"
              >
                {showToast && (
                  <div
                    style={{
                      width: "300px",
                      height: "40px",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "0",
                      left: "50%",
                      transform: "translate(-50%,0%)",
                      display: "grid",
                      placeItems: "center",
                      background: "#000",
                      color: "#ffffff",
                      fontFamily: "Cloister",
                      zIndex: "1",
                    }}
                  >
                    Contract Address Copied
                  </div>
                )}
                <Paragraph fs="20px" fs599="18px" fm="Cloister" color="#000">
                  Contract Address:
                </Paragraph>
                <div
                  style={{ display: "flex", alignItems: "centre", gap: "5px" }}
                >
                  <Paragraph color="#000" sx={{ wordBreak: "break-all" }}>
                    {addressToCopy}
                  </Paragraph>
                  <DataImage
                    src={copy}
                    onClick={handleCopy}
                    mw="15px"
                    style={{ cursor: "pointer", maxHeight: "21px" }}
                  />
                </div>
              </CustomGrid>
              <CustomGrid item xs={12}>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Paragraph fs="20px" fs599="18px" fm="Cloister">
                    Find us on:
                  </Paragraph>
                  <Link
                    href="https://etherscan.io/token/0x66e43a83749d07e272f1175ce4010a9b5bbdaebc"
                    target="blank"
                  >
                    <DataImage mw="40px" src={etherscan} />
                  </Link>
                  <Link
                    href="https://www.dextools.io/app/en/ether/pair-explorer/0xe6500960d28c4b1b2ee08a3fa7ddaaab3ea41364"
                    target="blank"
                  >
                    <DataImage mw="40px" src={dex} />
                  </Link>
                  <Link
                    href="https://app.uniswap.org/#/swap?outputCurrency=0x66e43a83749d07e272f1175ce4010a9b5bbdaebc&chain=ethereum"
                    target="blank"
                  >
                    <DataImage mw="40px" src={uniswap} />
                  </Link>
                  <Link href="https://t.me/Blackmale_token" target="blank">
                    <DataImage mw="40px" src={tg} />
                  </Link>
                  <Link
                    href="https://twitter.com/Blackmale_token"
                    target="blank"
                  >
                    <DataImage mw="40px" src={x} />
                  </Link>
                </div>
              </CustomGrid>
              <CustomGrid>
                {!showWelcome && (
                  <audio
                    id="my_audio"
                    ref={audioRef}
                    src={music}
                    type="audio/mpeg"
                    controls
                    autoPlay
                  ></audio>
                )}
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
          {showWelcome && (
            <CustomGrid
              container
              jc="center"
              sx={{
                position: "absolute",
                left: "0",
                top: "0",
                height: "100%",
                width: "100%",
                background: "#000000e8",
              }}
            >
              <CustomGrid
                jc="center"
                container
                item
                xs={11}
                sm={8}
                md={6}
                sx={{
                  minHeight: "300px",
                  background: "#2a2a2a",
                  borderRadius: "20px",
                }}
              >
                <CustomGrid jc="center" item xs={12}>
                  <SectionHeading>Black Male</SectionHeading>
                </CustomGrid>
                <CustomGrid jc="center" container item xs={12}>
                  <Paragraph fs="20px" fs599="18px" fm="Cloister" ta="center">
                    Do you want to become crypto Alpha Blackmale?
                  </Paragraph>
                </CustomGrid>
                <CustomGrid jc="center" item xs={12}>
                  <CustomButton
                    variant="contained"
                    onClick={() => setShowWelcome(false)}
                  >
                    Yes
                  </CustomButton>
                </CustomGrid>
              </CustomGrid>
            </CustomGrid>
          )}
        </Container>
      </ContainerWraper>
    </PageWrapper>
  );
};

export default MainPage;
